import * as React from "react";
import type { HeadFC } from "gatsby";

import { useWeb3Provider, Web3Providers } from "../contexts/AppContext";

import awesomeHat from "../images/awesome-hat.svg";

import { GoHomeButton } from "../components/Items/Buttons/Buttons";
import { NFTInfo, NFTMetadataJson } from "../services/NFTInfo";
import { PolakImageBox } from "../components/IndexSections/LoggedIn/MyPolacySection/MyPolacySection";

import { DashboardNavbar } from "../components/DashboardNavbar/DashboardNavbar";
import { Loader } from "../components/Loader";
import { OPENSEA_API_NFT_BASE_URL } from "../utils";
import { SEO } from "../components/SEO";

export const PolesNotFound = () => (
  <div className="owned-poles__notfound">
    <h2>
      Co tu się stało? <br />
      Nie znaleźliśmy ThePolaka na Twoim walletcie 👀 🤔
    </h2>
    <a
      href="https://opensea.io/collection/thepolacy"
      rel="noopener noreferrer"
      target="_blank"
      className="yellow-btn my-poles-section__button theshadow btn-clicked "
    >
      Nie bądź cebula... znajdź swojego przyjaciela drogi i dołącz do elitarnej
      ekipy :)
      <br />
      <br />
      Odblokuj dostęp do strony dołączając do naszej armii
      <br />
      <br />
      Obiecujemy, że będzie fajnie 🫡
    </a>
  </div>
);

const IndexPage = () => {
  return (
    <Web3Providers>
      <IndexPageComponent />
    </Web3Providers>
  );
};

const IndexPageComponent = () => {
  const web3Provider = useWeb3Provider();
  const [nfts, setNFTs] = React.useState<NFTMetadataJson[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (web3Provider.accountDetails?.address) {
      // use localstorage not to ask to many times about nfts per user (should ask only once when user logs in)
      let ownedNFTs = localStorage.getItem("nfts");
      // console.log(ownedNFTs)
      if (ownedNFTs == undefined || ownedNFTs == null) {
        // console.log("fetching");
        setIsLoading(true);
        NFTInfo.getUserNFTsImagesURIs(
          web3Provider.accountDetails?.address
        ).then(res => {
          localStorage.setItem("nfts", JSON.stringify(res));
          setNFTs(res);
          setIsLoading(false);
        });
      } else {
        const nftsFromCache = JSON.parse(ownedNFTs);
        setNFTs(nftsFromCache);
        setIsLoading(false);
      }
    }
  }, [web3Provider.accountDetails?.address]);

  return (
    <Web3Providers>
      <main className="owned-poles-page">
        {web3Provider.accountDetails?.address ? (
          <DashboardNavbar />
        ) : (
          <h1>Zaloguj się, aby zobaczyć swoich Polaków</h1>
        )}

        {/* <PageHeader
          title="Twoi Polacy"
          subtitle="Ziomeczki, które Cię identyfikują :)"
        /> */}

        <section className="owned-poles__section">
          <img
            className={"owned-poles__image"}
            src={awesomeHat}
            alt={"Polski kapelusz :)"}
          />

          <h1 className="owned-poles__title">Twoi Polacy</h1>
          {nfts.length > 0 && (
            <p className="owned-poles__description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
              pariatur suscipit alias quidem accusamus optio. Sunt at omnis quam
              earum.
            </p>
          )}
          <div className="owned-poles__box">
            {isLoading && <Loader />}
            {!isLoading &&
              nfts.map((nft: NFTMetadataJson) => {
                return (
                  <>
                    <PolakImageBox data={nft} />
                  </>
                );
              })}

            {!isLoading && nfts.length === 0 ? <PolesNotFound /> : null}
          </div>

          <GoHomeButton />
        </section>
      </main>
    </Web3Providers>
  );
};

export default IndexPage;

// export const Head: HeadFC = () => <title>Sąsiedzi</title>;
export const Head: HeadFC = () => <SEO subtitle="Twoi Polacy" />;
