import * as React from "react";
import "./Images.scss";

export interface ISectionImage {
  imageUrl: string;
  alt: string;
  className?: string;
}
export const SectionImage = ({ imageUrl, alt, className }: ISectionImage) => {
  return <img className={`images ${className}`} src={imageUrl} alt={alt} />;
};
