import { navigate } from "gatsby";
import * as React from "react";
import { useWeb3Provider } from "../../../../contexts/AppContext";
import awesomeHat from "../../../../images/awesome-hat.svg";
import { PolesNotFound } from "../../../../pages/ownedpolacy";
import { NFTInfo, NFTMetadataJson } from "../../../../services/NFTInfo";
import { calculatePages, OPENSEA_API_NFT_BASE_URL } from "../../../../utils";
import { YellowButton } from "../../../Items/Buttons/Buttons";
import { SectionImage } from "../../../Items/Images/Images";
import { PaginateButtons, Pagination } from "../../../Items/Pagination";
import { Loader } from "../../../Loader";
import "./MyPolacySection.scss";

interface IPolakImage {
  data: NFTMetadataJson;
}
export const PolakImage = ({ data }: IPolakImage) => {
  return (
    <figure className="pole-image">
      <h3 className="pole-image__header">{data.name}</h3>
      <a
        href={`${OPENSEA_API_NFT_BASE_URL}/${data.edition}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="pole-image__photo" src={data.image} alt={data.dna} />
      </a>
    </figure>
  );
};

export const PolakImageBox = ({ data }: IPolakImage) => {
  return (
    <figure className="pole-image__box theshadow radius">
      <h3 className="pole-image__header">{data.name}</h3>
      <a
        href={OPENSEA_API_NFT_BASE_URL + "/" + data.edition}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="pole-image__photo" src={data.image} alt={data.dna} />
      </a>
    </figure>
  );
};

export const MyPolacySection = ({
  nfts,
  isLoading,
}: {
  nfts: NFTMetadataJson[];
  isLoading: boolean;
}) => {
  const webProvider = useWeb3Provider();
  const [polacy, setUserPolacy] = React.useState<NFTMetadataJson[]>([]);
  const [pages, setPages] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  // const [isLoading, setIsLoading] = React.useState(false);

  const dataLimit = 1;

  const nextPage = () => {
    if (currentPage + 1 === pages) setCurrentPage(0);
    setCurrentPage(page => page + 1);
  };

  const prevPage = () => {
    if (currentPage - 1 === 0) setCurrentPage(pages - 1);
    setCurrentPage(page => page - 1);
  };

  React.useEffect(() => {
    const fetchNFTsDetails = async () => {
      // setIsLoading(true);
      const pages = calculatePages(nfts, dataLimit);
      setPages(pages);
      setUserPolacy(nfts);
      // setIsLoading(false);
    };

    if (webProvider.accountDetails?.address) {
      fetchNFTsDetails();
    }
  }, [webProvider.accountDetails?.address, currentPage, nfts]);

  return (
    <section className="summary-section my-poles-section" id="my-polacy">
      <SectionImage
        imageUrl={awesomeHat}
        alt={"Polski kapelusz :)"}
        className="my-poles-section__section-image"
      />
      <div className="my-poles-section__box">
        <div className="title my-poles-section__description">
          <span className="my-poles-section__title big-title">
            Jestem dumny. <br />
            Bo jestem ThePolakiem
          </span>
          <p className="my-poles-section__text text-lg">
            Każdy posiadacz{" "}
            <a
              href="https://twitter.com/thePolacy_"
              target="_blank"
              rel="noopener"
            >
              @ThePolacyNFT
            </a>{" "}
            zasługuje na to, by być milionerem! Tu w web3 jesteś w okopach,
            mieląc każdego pieprzonego dnia degeński żwir by zarobić na dostępny
            tylko dla najlepszych Zdecentralizowany Chleb.ThePolacy to Twoja
            braterska kompania!
          </p>

          <a
            href="https://opensea.io/collection/thepolacy"
            rel="noopener noreferrer"
            target="_blank"
            className="red-btn my-poles-section__button theshadow btn-clicked"
          >
            Dokup ThePolaka
          </a>

          <a
            href="/rejestr"
            className="yellow-btn my-poles-section__button theshadow btn-clicked"
          >
            Zobacz Sławnych ThePolaków
          </a>
        </div>

        <div className="my-poles-section__pole-card theshadow radius my-polacy-small-slider">
          {/* {!isLoading && polacy.length === 0 ? <PolesNotFound /> : null} */}
          {isLoading && <Loader />}
          {!isLoading &&
            (polacy.length > 0 ? (
              <>
                <PaginateButtons
                  prevPage={prevPage}
                  nextPage={nextPage}
                  currentPage={currentPage}
                  pages={pages}
                />

                <Pagination
                  data={polacy}
                  RenderComponent={PolakImage}
                  dataLimit={1}
                  currentPage={currentPage}
                />

                <YellowButton
                  title={"Zobacz swoich The Polaków"}
                  action={() => {
                    // window.location.href = "/ownedpolacy";
                    navigate("/ownedpolacy");
                  }}
                  className="my-poles-section__more-button btn-clicked"
                />
              </>
            ) : (
              <PolesNotFound />
              // <></>
            ))}
        </div>
      </div>
    </section>
  );
};
