import * as React from "react";
import { useState } from "react";
import sliderLeftArrow from "../../images/slider-left-arrow.svg";
import sliderRightArrow from "../../images/slider-right-arrow.svg";

export const Pagination = ({
  data,
  RenderComponent,
  dataLimit,
  currentPage,
}: any) => {
  const [items, setItems] = useState<any[]>([]);
  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  React.useEffect(() => {
    const itms = getPaginatedData();
    setItems(itms);
  }, [data, currentPage]);

  return (
    <>
      {items.map((item: any, index: number) => {
        return <RenderComponent data={item} key={index} />;
      })}
    </>
  );
};

export interface IPaginateButtons {
  prevPage: any;
  nextPage: any;
  currentPage: any;
  pages: any;
}
export const PaginateButtons = ({
  prevPage,
  nextPage,
  currentPage,
  pages,
}: IPaginateButtons) => {
  return (
    <div className="pagination">
      {" "}
      <button
        onClick={prevPage}
        className={`prev ${currentPage === 1 ? "disabled" : ""}`}
      >
        <img src={sliderLeftArrow} alt="" />
      </button>
      <button
        onClick={nextPage}
        className={`next ${currentPage === pages ? "disabled" : ""}`}
      >
        <img src={sliderRightArrow} alt="" />
      </button>
    </div>
  );
};
